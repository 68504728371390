<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="10" sm="10">
        <va-form>
          <va-text-input
            v-model="announcement.title"
            label="Заголовок"
          ></va-text-input>
          <h3>Данные по объявлению</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="selectedSection.label"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedCategory"
                :items="categories"
                label="Выбрать категорию"
                chips
                item-text="title"
                solo
                return-object
                @change="selectCategory"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedSubcategory"
                :items="subcategories"
                chips
                label="Выбрать подкатегорию"
                item-text="title"
                solo
                return-object
                @change="selectSubcategory"
              ></v-select>
            </v-col>
            <v-col v-if="isSpecialEquipment" cols="12" sm="4">
              <v-select
                v-model="announcement.markId"
                :items="marksList"
                chips
                label="Выбрать марку"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col v-if="!isSpecialEquipment" cols="12" sm="4">
              <v-select
                v-model="announcement.unitId"
                :items="unitList"
                chips
                label="Выбрать unit"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col v-if="isSpecialEquipment" cols="12" sm="4">
              <v-select
                v-model="announcement.unit"
                :items="specialUnits"
                chips
                label="Выбрать unit"
                item-text="title"
                item-value="title"
                solo
              ></v-select>
            </v-col>
            <v-col v-if="isSpecialEquipment" cols="12" sm="4">
              <v-text-field
                v-model="announcement.yearOfIssue"
                label="Год выпуска"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="announcement.description"
                outlined
                name="input-7-1"
                label="Описание"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.price"
                label="Цена"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="announcement.delivery"
                :label="`Доставка: ${announcement.delivery ? 'Да' : 'Нет'}`"
              ></v-switch>
            </v-col>
          </v-row>

          <h3 v-if="filters.length">Технические характеристики</h3>
          <v-row>
            <v-col v-for="filter in filters" :key="filter.id" cols="12" sm="4">
              <h4>{{ filter.title }}</h4>
              <v-select
                v-model="announcement.filtersCustom[filter.id]"
                :items="filter.elements"
                item-value="id"
                item-text="value"
              ></v-select>
            </v-col>
          </v-row>

          <h3 v-if="isSpecialEquipment">Выберите занятые дни</h3>
          <v-row v-if="isSpecialEquipment">
            <v-col cols="12" sm="6">
              <v-date-picker v-model="dates" multiple></v-date-picker>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <v-date-picker v-model="dates" multiple no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <h3>Фотографии</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-file-input
                v-model="images"
                label="Загрузить фотографии"
                counter
                multiple
                accept="image/*"
                prepend-icon="mdi-camera"
                return-object
                @change="onPhotoChange"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="image in announcement.images"
              :key="image.name"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                v-if="image.link"
                :src="image.link"
                aspect-ratio="1"
                class="grey lighten-2"
              />
              <v-img
                v-else
                :src="getImage(image)"
                aspect-ratio="1"
                class="grey lighten-2"
              />
            </v-col>
          </v-row>

          <h3>Контактные данные</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.phoneNumber"
                label="Номер телефона"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.email"
                label="Электроная почта"
                type="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.secondPhoneNumber"
                label="Номер телефона"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <h3>Местоположение</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectedRegion"
                :items="regions"
                chips
                label="Выбрать регион"
                item-text="region"
                return-object
                solo
                @change="loadCitiesByRegion"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectedCity"
                :items="cities"
                chips
                label="Выбрать город"
                item-text="city"
                return-object
                solo
              ></v-select>
            </v-col>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="announcement.latidude"
                  label="Широта"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="announcement.longitude"
                  label="Долгота"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>

          <v-btn
            v-if="isShowSaveButton"
            depressed
            color="primary"
            @click="save"
          >
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/regions";

export default {
  name: "CreateForm",
  components: {},
  props: {
    section: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          name: "special-equipment",
          label: "Спецтехника",
        },
        { id: 2, name: "material", label: "Материалы" },
        { id: 3, name: "vacancy", label: "Операторы/Водители" },
      ],
      selectedDate: null,
      selectedCategory: null,
      selectedSubcategory: null,
      selectedRegion: null,
      selectedCity: "",
      marksList: null,
      filters: [],
      announcement: {
        phoneNnumber: null,
        secondPhoneNumber: null,
        email: null,
        latidude: null,
        longitude: null,
        delivery: false,
        cityId: null,
        price: null,
        title: null,
        description: "",
        subcategoryId: null,
        images: [],
        markId: null,
        yearOfIssue: null,
        filters: [],
        unitId: null,
        unit: "",
        filtersCustom: {},
      },
      specialUnits: [
        {
          id: 1,
          title: "за час",
        },
        {
          id: 2,
          title: "за день",
        },
        {
          id: 3,
          title: "за смену",
        },
      ],
      unitList: [],
      images: [],
      dates: [],
      menu: false,
      categories: null,
    };
  },

  computed: {
    ...mapState("regionsModule", ["cities", "regions"]),

    subcategories() {
      return this.categories && this.selectedCategory
        ? this.categories.find((c) => c.id === this.selectedCategory.id)
            ?.subcategories
        : [];
    },
    selectedSection() {
      return this.sections.find((s) => s.name === this.section);
    },
    isSpecialEquipment() {
      return this.selectedSection.name === "special-equipment";
    },
    isShowSaveButton() {
      return this.action !== "show";
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        console.log("ITEM", this.item);
        if (this.item) {
          this.initDefaultValues();
          this.setItem();
        } else {
          this.filters = [];
          this.selectedCategory = null;
          this.selectedSubcategory = null;
          this.selectedRegion = null;
          this.announcement = {
            phoneNnumber: null,
            secondPhoneNumber: null,
            email: null,
            latidude: null,
            longitude: null,
            delivery: false,
            cityId: null,
            price: null,
            title: null,
            description: "",
            subcategoryId: null,
            images: [],
            markId: null,
            yearOfIssue: null,
            filters: [],
            unit: "",
            unitId: null,
            filtersCustom: {},
          };
        }
      },
    },
  },
  mounted() {
    this.initDefaultValues();

    console.log("isShowSaveButton", this.isShowSaveButton);
    if (this.item) {
      this.setItem();
    }
  },

  methods: {
    ...mapActions("regionsModule", {
      loadRegions: actionTypes.loadRegions,
      loadCities: actionTypes.loadCities,
    }),
    initDefaultValues() {
      this.$admin.http
        .get(`${this.selectedSection.name}/categories`)
        .then((response) => {
          this.unitList = response.data.data.units;
        });
      // this.selectedSection = this.sections.find(s=>s.name == this.section)
      this.$admin.http
        .get(`/${this.selectedSection.name}/categories`)
        .then((response) => {
          this.categories = response.data.data.categories;
          this.marksList = response.data.data.marks;
          if (this.item) {
            this.selectedCategory = this.categories.find(
              (c) => c.id === this.item?.category_id
            );
            if (this.item?.subcategory_id) {
              this.selectedSubcategory =
                this.selectedCategory?.subcategories.find(
                  (sc) => sc.id === this.item.subcategory_id
                );
            }
            this.getFilters();
          }
        });
      this.loadRegions().then(() => {
        if (this.item) {
          this.selectedRegion = this.regions.find(
            (r) => r.id === this.item.region_id
          );
          this.loadCities(this.selectedRegion.id).then(() => {
            this.selectedCity = this.cities.find(
              (c) => c.id === this.item.city_id
            );
          });
          // this.loadCitiesByRegion().then((cities) => {
          //   this.selectedCity = cities.find((c) => c.id === this.item.city_id);
          // });
        }
      });
      // this.$admin.http.get("/regions").then((response) => {
      //   this.regions = response.data.data.regions;
      // });
    },
    setItem() {
      this.announcement = {
        phoneNumber: this.item.phone_number,
        secondPhoneNumber: this.item.second_phone_number,
        email: this.item.email,
        latidude: this.item.latitude,
        longitude: this.item.longitude,
        delivery: this.item.delivery !== 0,
        price: this.item.price,
        title: this.item.title,
        description: this.item.description,
        images: this.mapImagesToObject(this.item.images),
        filtersCustom: {},
        unit: this.item.unit,
      };
      if (this.item?.filters) {
        this.announcement.filters = this.item?.filters?.map((e) => e.id);
      }
      if (this.isSpecialEquipment) {
        this.selectedSubcategory = this.subcategories.find(
          (s) => s.id === this.item.subcategory_id
        );
        this.announcement.markId = this.item.mark_id;
        this.announcement.yearOfIssue = this.item.year_of_issue;
        this.dates = this.item.employment_calendar;
      }
      if (this.section === "material") {
        (this.announcement.unitId = this.item.unit_id),
          (this.selectedCategory = this.categories.find(
            (s) => s.id === this.item.category_id
          ));
      }
    },

    save() {
      if (!this.item || this.action === "create") {
        this.$emit("save", {
          route: `/${this.selectedSection.name}/announcement`,
          data: this.mapAnnouncment(),
        });
        return;
      }
      this.$emit("update", {
        route: `/${this.selectedSection.name}/announcement/${this.item.id}`,
        data: this.mapAnnouncment(),
      });
      console.log("lol");
    },
    mapImagesToObject(arr) {
      const images = arr.map((imageUrl) => {
        return { link: imageUrl };
      });
      return images;
    },
    mapAnnouncment() {
      const announcement = {
        filters: Object.values(this.announcement.filtersCustom).filter(
          (f) => f !== undefined && f !== null
        ),
        title: this.announcement.title,
        description: this.announcement.description,
        city_id: this.selectedCity?.id,
        price: this.announcement.price,
        phone_number: this.announcement.phoneNumber,
        second_phone_number: this.announcement.secondPhoneNumber,
        email: this.announcement.email,
        delivery: this.announcement.delivery,
        latitude: this.announcement.latidude || 55.55,
        longitude: this.announcement.longitude || 70.77,
        images: this.announcement.images,
      };

      if (this.selectedSection?.name === "special-equipment") {
        announcement.mark_id = this.announcement.markId;
        announcement.year_of_issue = this.announcement.yearOfIssue;
        announcement.subcategory_id = this.selectedSubcategory?.id;
        announcement.employment_calendar = this.dates;
        announcement.unit = this.announcement.unit;
      }
      if (this.selectedSection?.name === "material") {
        announcement.category_id = this.selectedCategory.id;
        announcement.unit_id = this.announcement.unitId;
        announcement.subcategory_id = this.selectedSubcategory?.id;
      }

      return announcement;
    },

    selectCategory() {
      this.selectedSubcategory = null;
      this.getFilters();
    },
    selectSubcategory() {
      this.getFilters();
    },

    changeElements(elementId) {
      console.log(elementId);
      const foundElementIndex = this.announcement.filters.findIndex(
        (e) => e === elementId
      );
      if (foundElementIndex === -1) {
        this.announcement.filters.push(elementId);
        return;
      }
      this.announcement.filters.splice(foundElementIndex, 1);
    },

    onPhotoChange() {
      this.images.forEach((file) => {
        this.convertBase64(file);
      });
    },
    convertBase64(file) {
      const ext = file.name.split(".")[1];
      const reader = new FileReader();
      reader.onload = () => {
        this.announcement.images.push({ [ext]: reader.result });
      };
      reader.readAsDataURL(file);
    },
    getImage(file) {
      return file["jpg"] || file["png"] || file["svg"] || file["gif"];
    },
    loadCitiesByRegion() {
      console.log("CITES");
      this.loadCities(this.selectedRegion?.id);
    },
    getFilters() {
      let filterParamName =
        "category_" + this.selectedSection?.name?.replace(/-/gi, "_");
      let query = { [filterParamName]: this.selectedCategory?.id };

      if (this.selectedSubcategory) {
        filterParamName =
          "subcategory_" + this.selectedSection?.name?.replace(/-/gi, "_");
        query = { [filterParamName]: this.selectedSubcategory.id };
      }

      this.$admin.http
        .get(`/announcement/filters`, { params: query })
        .then((response) => {
          this.filters = response.data.data;
          if (this.item) {
            this.filters.forEach((el) => {
              this.announcement.filtersCustom[el.id] = this.item?.filters?.find(
                (f) => f.id === el.id
              )?.value?.id;
            });
          }
        });
    },
  },
};
</script>
